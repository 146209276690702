<template>
  <svg fill="none" viewBox="0 0 31.5 31.5">
    <title>Gold Disc</title>
    <path
      d="m15.75 31.5c8.6985 0 15.75-7.0515 15.75-15.75s-7.0515-15.75-15.75-15.75-15.75 7.0515-15.75 15.75 7.0515 15.75 15.75 15.75z"
      fill="#b8ab88"
    />
  </svg>
</template>
<script setup></script>
