<template>
  <div
    class="overflow-hidden h-screen relative"
    :id="`scrollWrapper${this._uid}`"
  >
    <div
      v-if="image"
      :class="`h-full w-full bg-center bg-cover bg-no-repeat scale-150 absolute top left`"
      :style="`background-image: url(${image})`"
    ></div>
    <div
      class="h-screen relative text-ol-purple"
      :class="{ 'bg-ol-cream': !image }"
      :id="`scrollContent${this._uid}`"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import KnockOnText from './KnockOnText.vue';

gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'VerticalTextAnim',
  components: { KnockOnText },

  props: {
    image: String,
    blur: {
      type: String,
      default: '75',
    },
  },

  mounted() {
    let wrapperId = '#scrollWrapper' + this._uid;
    let contentId = 'scrollContent' + this._uid;
    let items = gsap.utils.toArray(wrapperId + ' .knock');
    let end = document.getElementById(contentId).children.length * 600;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperId,
        start: 'bottom bottom',
        end: '+=' + end,
        scrub: 2,
        snap: 1 / (items.length - 1),
        pin: true,
      },
    });
    for (let i = 0; i < items.length; i++) {
      if (i !== 0) {
        tl.from(items[i], { opacity: 0, y: 500 }, '<-=25%');
      }
      tl.addLabel('middle');
      if (i !== items.length - 1) {
        tl.to(items[i], {
          opacity: 0,
          y: -500,
        });
      }
    }
  },
};
</script>
