<template>
  <svg fill="none" viewBox="0 0 31.5 31.5" xmlns="http://www.w3.org/2000/svg">
    <title>Number 3</title>
    <path
      d="m15.75 31.5c8.6985 0 15.75-7.0515 15.75-15.75s-7.0515-15.75-15.75-15.75-15.75 7.0515-15.75 15.75 7.0515 15.75 15.75 15.75z"
      fill="#b8ab88"
    />
    <path
      d="m11.812 8.53h7.875l-4.5927 6.5638c0.7554 0 1.4992 0.1863 2.1655 0.5424 0.6662 0.3562 1.2344 0.8711 1.6541 1.4992s0.678 1.3501 0.752 2.1019c0.0741 0.7518-0.0384 1.5103-0.3275 2.2082-0.2891 0.698-0.7459 1.3138-1.3299 1.7931-0.5839 0.4792-1.2771 0.8071-2.018 0.9544-0.7409 0.1474-1.5068 0.1098-2.2297-0.1095s-1.3806-0.6135-1.9148-1.1477"
      stroke="#261842"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>
