<template>
  <section
    class="bg-ol-cream text-ol-dark-ink-200 flex flex-col items-center px-5 py-20 lg:py-28 gap-y-12"
  >
    <container class="gap-y-10">
      <div v-if="steps.title" class="col-span-8 lg:col-span-6 lg:col-start-1">
        <div class="flex flex-col gap-y-8">
          <h3
            class="text-center lg:text-left font-bold text-3xl md:text-4xl lg:text-6xl"
            data-testid="will-steps-header"
          >
            {{ steps.title }}
          </h3>
        </div>
      </div>
      <div class="reverse col-span-8 lg:col-span-6">
        <div class="flex flex-col gap-y-7">
          <div v-for="(step, index) in steps.steps" class="flex justify-center">
            <div>
              <component :is="step.svg" class="w-8"></component>
              <VerticalLine
                class="ml-4 mt-8"
                v-if="index !== steps.steps.length - 1"
              ></VerticalLine>
            </div>
            <div
              class="text-ol-dark-ink-200 mx-5 lg:mr-0 ml-4 flex flex-col relative gap-y-3"
            >
              <h4 class="text-xl font-bold lg:text-3xl">
                {{ step.name }}
              </h4>

              <p class="text-lg lg:text-2xl">
                {{ step.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </container>
  </section>
</template>
<script>
import Container from '@components/Core/Container.vue';
import NumberOne from '@components/Icon/NumberOne.vue';
import NumberTwo from '@components/Icon/NumberTwo.vue';
import NumberThree from '@components/Icon/NumberThree.vue';
import GoldDisc from '@components/Icon/GoldDisc.vue';
import VerticalLine from '@components/Icon/VerticalLine.vue';

export default {
  components: {
    VerticalLine,
    Container,
    NumberOne,
    NumberTwo,
    NumberThree,
    GoldDisc,
  },
  props: ['steps'],
};
</script>
