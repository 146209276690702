import { createStore } from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

const user = {
  namespaced: true,
  state: {
    ...window.guardianAngel.user,
  },
  getters: {
    isAuthenticated: (state) => !!window.guardianAngel?.user?.hashid,
    getAvatar: (state) => state?.avatar ?? '',
    getName: (state) => state?.name ?? '',
    getEmail: (state) => state?.email ?? '',
  },
  mutations: {
    setUser(state, user) {
      state.name = user.name;
      state.avatar = user.avatar;
      state.email = user.email;
      state.is_member = user.is_member;
    },
  },
};

export default createStore({
  strict: debug,
  modules: { user },
});
