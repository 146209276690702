<template>
  <svg fill="none" viewBox="0 0 31.5 31.5" xmlns="http://www.w3.org/2000/svg">
    <title>Number 2</title>
    <path
      d="m15.75 31.5c8.6985 0 15.75-7.0515 15.75-15.75s-7.0515-15.75-15.75-15.75-15.75 7.0515-15.75 15.75 7.0515 15.75 15.75 15.75z"
      fill="#b8ab88"
    />
    <path
      d="m12.122 10.28c0.2138-0.5059 0.5311-0.9614 0.9314-1.3374s0.8748-0.6641 1.3931-0.8458c0.5182-0.1817 1.0687-0.253 1.6162-0.2093 0.5474 0.0437 1.0797 0.2014 1.5626 0.4629 0.4829 0.2616 0.9057 0.6213 1.2413 1.056s0.5766 0.9348 0.7075 1.4682c0.1309 0.5333 0.1488 1.0882 0.0525 1.6289-0.0962 0.5406-0.3045 1.0552-0.6115 1.5106v0l-7.2025 9.611v-9e-4h7.875"
      stroke="#261842"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>
