<template>
  <div class="order-3">
    <div
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="657080ebfa8c7e8d395f33bd"
      data-style-height="24px"
      data-style-width="280px"
      data-theme="dark"
      data-font-family="Montserrat"
      data-text-color="#FFFEF3"
    ></div>
  </div>
</template>
