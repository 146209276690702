<script>
export default {
  name: 'VerticalScrollItem',
};
</script>

<template>
  <div class="w-full h-full flex justify-center items-center absolute knock">
    <slot />
  </div>
</template>
