<template>
  <svg fill="none" viewBox="0 0 31.5 31.5">
    <title>Number 1</title>
    <path
      d="m15.75 31.5c8.6985 0 15.75-7.0515 15.75-15.75s-7.0515-15.75-15.75-15.75-15.75 7.0515-15.75 15.75 7.0515 15.75 15.75 15.75z"
      fill="#b8ab88"
    />
    <path
      d="m12.469 11.155 3.9374-2.6239v15.094"
      stroke="#261842"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>
