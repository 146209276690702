<template>
  <svg
    width="4"
    height="108"
    viewBox="0 0 4 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Line 29"
      d="M2 2L2 106"
      stroke="#B8AB88"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>
